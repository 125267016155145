// base button

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10rem;

  transition: $ease;
  text-decoration: none;

  color: #ffffff;

  font-family: 'montserrat', 'sans-serif';
  font-size: 16rem;
  font-weight: 500;
  line-height: 1.4;

  &:hover,
  &:focus {
    @media screen and (min-width: $sm) {
      color: #ffffff;
    }
  }
}

.button__icons {
  display: flex;

  margin-right: 14rem;

  & > :not(:last-child) {
    margin-right: 11rem;
  }

  img {
    width: 35rem;
  }
}

// aside-head

.button--desktop-download {
  display: flex;

  padding: 16rem 23rem 17rem 14rem;

  background-color: #363636;
  &:hover,
  &:focus {
    @media screen and (min-width: $sm) {
      background-color: lighten(#363636, 5%);
    }
  }
}

.button--login {
  display: flex;
  justify-content: center;

  min-width: 251rem;

  border: 1rem solid #ffffff;
  background-color: #000000;
  &:hover,
  &:focus {
    @media screen and (min-width: $sm) {
      background-color: lighten(#000000, 10%);
    }
  }
}

// aside get-freebet

.button--freebet-circle {
  padding: 25rem 46rem;

  text-transform: uppercase;

  border-radius: 100rem;
  background-color: $lavand;
  box-shadow: 0 22rem 45rem rgba($lavand, 0.5);

  font-size: 35rem;
  font-weight: 700;

  &:hover,
  &:focus {
    @media screen and (min-width: $sm) {
      box-shadow: 0 22rem 35rem rgba($lavand, 0.7);
    }
  }
}

.button--freebet-parallelogram {
  padding: 25rem 46rem;

  text-transform: uppercase;
  position: relative;

  font-size: 35rem;
  font-weight: 700;

  cursor: pointer;

  &::before {
    @include parallelogram();
    content: '';
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: $lavand;
    transition: $ease;
    box-shadow: 0 22rem 45rem rgba($lavand, 0.5);
  }

  &:hover,
  &:focus {
    &::before {
      @media screen and (min-width: $sm) {
        box-shadow: 0 22rem 35rem rgba($lavand, 0.7);
      }
    }
  }
}

// rules

.button--rules {
  text-decoration: underline;
  &:hover,
  &:focus {
    @media screen and (min-width: $sm) {
      opacity: 0.7;
    }
  }
}

// signin

.button--signin {
  display: inline-block;

  padding: 0;

  text-decoration: underline;
  &:hover,
  &:focus {
    @media screen and (min-width: $sm) {
      opacity: 0.7;
    }
  }
}

@media screen and (max-width: $sm) {
  .button {
    font-size: 12rem;
  }

  // base button
  .button--freebet-circle,
  .button--freebet-parallelogram {
    padding: 16rem 10rem;

    font-size: 23rem;
  }
}
