.form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.form__input-count {
  padding: 0;
  display: inline-block;
  margin: 0;
  border: 0;
  border: none;
  outline: 0;
  background-color: transparent;
  font-size: 43rem;
  color: #fff;
  -webkit-appearance: none;
  -moz-appearance: textfield;

  &::placeholder {
    color: #FFFFFF;
    opacity: 1;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  text-align: center;
  width: 100%;
  height: 100%;
}

.form__counter {
  display: flex;
  justify-content: center;
  margin-left: 20rem;
}

.form__counter-buttons {
  display: flex;
  flex-direction: column;
}

.form__number {
  position: relative;
  right: 12rem;
  width: 111.4rem;
  height: 78.78rem;
  display: block;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-image: url('../images/vector/counter.svg');
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
  }
}

.form__counter-buttons {
  margin-bottom: 15rem;
}

.form__counter-button {
  @include button-reset;
  width: 52.8rem;
  height: 37.3rem;
  background-image: url('../images/vector/arrow-up.svg');
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;

  &:active {
    transform: scale(0.8);

  }

  &:last-child {
    position: relative;
    margin-top: 3rem;
    right: 12rem;
    transform: scale(-1);

    &:active {
      transform: scale(-1) scale(0.8);
    }
  }

}

.form__counter-title {
  font-size: 20rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
}

.form__vs {
  margin: 0 0 10rem;
  color: $lazur;
  font-size: 43rem;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
}

.form__counter--second {
  .form__counter-buttons {
    margin-left: -11rem;
  }
}

.form__btn {
  margin: 30rem 0;
  color: #000;
  padding: 0;
  width: 95%;

  &::before {
    background-color: #fff;
    box-shadow: 0 16.5rem 36rem rgba(255, 255, 255, 0.38);
  }
}

.form__tel {
  position: relative;
  width: 270rem;
  height: 51rem;
  margin-bottom: 15rem;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2rem solid #fff;
    border-radius: 6.51513rem;
    transform: $parallelogram;
    pointer-events: none;
  }


  &--error {
    &::before {
      border-color: #DC143C;
      box-shadow: 0 0 45rem rgba(220, 20, 60, 0.4);
    }
  }
}

.form__input-tel {
  margin: 0;
  outline: none;
  border: 0;
  font-weight: 500;

  color: #ffffff;
  background: transparent;

  padding: 15rem 0 17rem 20rem;
  width: 287rem;
  height: 51rem;
  font-size: 16rem;
  line-height: 20rem;

  &::placeholder {
    font-weight: 500;
    font-size: 14rem;
    line-height: 17rem;
    color: rgba(255, 255, 255, 0.3);
  }
}

.form__agree {
  padding-left: 10rem;
  padding-right: 10rem;
  margin-bottom: 40rem;
  font-size: 11rem;
  color: rgba(255, 255, 255, 0.3);

  a {
    color: rgba(255, 255, 255, 0.3);
  }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.form__checkbox + label {
  position: relative;
  display: flex;
  align-items: flex-start;
  user-select: none;
}

.form__checkbox {
  position: absolute;
  z-index: -1;
  width: 15rem;
  height: 15rem;
  opacity: 0;
}

.form__checkbox + label:after, .form__checkbox + label:before {
  content: "";
  display: inline-block;
}

.form__checkbox + label:before {
  margin-top: 3.2rem;
  width: 18rem;
  height: 18rem;
  border: 1rem solid #9E9E9E;
  transition: box-shadow .3s ease;
  border-radius: 3rem;
  flex-shrink: 0;
  flex-grow: 0;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% auto;
}

.form__checkbox + label:after {
  width: 13rem;
  height: 12rem;
  position: absolute;
  top: 6.33rem;
  left: 2.4888rem;
  transform: scale(0);
  background: $lazur;
  transition: transform .3s ease;
}

.form__checkbox:checked + label:after {
  transform: scale(1);
}

.form__check {
  margin-bottom: 31rem;

  label {
    margin-top: 41rem;
    margin-left: -4rem;
  }
}

.form__wrapper--repeat {
  display: flex;
  align-items: center;
  height: 85%;

  h2 {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.form__wrapper--result{
  .popup-dark__title {

    margin-bottom: 10rem;
  }
}

@media screen and (min-width: $sm) {


  .form__score {
    display: flex;
    justify-content: space-between;

  }

  .form__counter-buttons {

  }

  .form__counter-button {
    width: 85.8rem;
    height: 60.6rem;

    &:last-child {
      right: 18rem;
    }
  }

  .form__number {
    width: 181rem;
    height: 128rem;

    &::before {
      width: 100%;
      height: 100%;
    }
  }

  .form__input-count {
    font-size: 70rem;
  }

  .form__vs {
    margin-bottom: 37rem;
    align-self: center;
    font-size: 70rem;
  }

  .form__btn {
    width: auto;
    padding: 41rem 60rem;
    margin: 62rem auto 0;
    font-size: 30rem;

    &:hover {
      color: #000000;

      &::before {
        box-shadow: 0 23rem 51rem #ffffff61;
      }
    }
  }

  .form__tel {
    width: 100%;
    min-height: 72rem;
  }

  .form__input-tel {
    font-size: 25rem;
    width: 100%;
    height: 100%;

    &::placeholder {
      font-size: 25rem;
      line-height: 29rem;
    }
  }

  .form__agree {
    font-size: 20rem;
  }

  .form__check {

    label {
      &::before {
        margin-top: 7rem;
        width: 23rem;
        height: 23rem;
      }

      &::after {
        width: 19rem;
        height: 19rem;
        border-radius: 3rem;
        top: 9rem;
        left: 2rem;
      }
    }
  }

  .form__freebet {
    width: 460rem;
    height: 120rem;
    margin: 0 auto 100rem;
  }
  .form__wrapper--result{
    .popup-dark__title {
      margin-bottom: 30rem;
    }

    .form__btn {
      display: flex;
      max-width: 467rem;
      color: #000000;
    }
  }

  .form__wrapper--timer-expired {
    margin-top: 170rem;
  }
}

