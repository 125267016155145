.popup-dark {
  min-height: 100vh;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow-y: scroll;
  overflow-x: hidden;

  &--active {
    display: block;
  }
}

.success {
  .popup-dark__btn-close {
    top: -126rem;
  }
}

.popup-dark__title {
  margin: 50rem auto 35rem;
  color: #FFFFFF;
  font-weight: 900;
  font-size: 24rem;
  line-height: 29rem;
  text-transform: uppercase;
  text-align: center;
}

.popup-dark__content {
  z-index: 2;
  top: 50%;
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10rem;
  padding-bottom: 10rem;
  padding-left: 27rem;
  padding-right: 14rem;
  background-color: black;
  height: 100%;
}

.popup-dark__overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 25rem;
  padding-bottom: 50px;
  //background-color: rgba(0, 0, 0, 0.6);
  background-color: black;
}

.popup-dark__container {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.popup-dark__btn-close {
  outline: none;
  position: absolute;
  z-index: 2;
  padding: 0;
  margin: 0;
  padding-top: 25rem;
  padding-bottom: 25rem;
  padding-left: 25rem;
  padding-right: 25rem;
  border: none;
  top: -16rem;
  right: 9rem;
  width: 22rem;
  height: 22rem;
  background-color: transparent;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 8rem;
    left: 23rem;
    height: 31rem;
    width: 3rem;
    background-color: #FFFFFF;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}


.popup-dark__reg {
  display: block;
  margin: 0 auto;
  text-align: center;
  font-weight: 500;
  font-size: 14rem;
  line-height: 17rem;
  text-decoration-line: underline;
  color: #FFFFFF;
}

.popup-dark__btn {
  position: relative;
  margin: 0 auto;
  margin-bottom: 26rem;
  //width: 293rem;
  width: 100%;
  height: 60rem;
  font-weight: 700;
  font-size: 20rem;
  line-height: 24rem;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $lazur;
    box-shadow: 0 0 8rem rgba(255, 255, 255, 0.4), 0 0 30rem #00C7B1;
    border-radius: 6.6526rem;
    transform: $parallelogram;
    z-index: -1;
  }
}

.popup-dark__text {
  color: #fff;
  font-size: 16rem;
  line-height: 19rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: 20rem;
}

@media screen and (min-width: $sm) {
  .popup-dark__overlay {
    width: 100%;
    height: 100%;
    min-height: 800rem;
    padding-top: 17rem;
    padding-bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .popup-dark__container {
    width: 828rem;
    height: 677rem;
    background-color: black;
  }

  .popup-dark__content {
    margin: 0;
    padding-top: 50rem;
    border-radius: 15rem;
    padding-left: 70rem;
    padding-right: 70rem;
  }

  .popup-dark__title {
    margin-top: 10rem;
    margin-bottom: 61rem;
    max-width: 100%;
    font-size: 50rem;
    line-height: 61rem;
  }

  .popup-dark__btn-close {
    top: 19rem;
    right: 16rem;

    &::before, &::after {
      width: 1rem;
    }
  }

  .popup-dark__btn {
    width: 272rem;
    height: 60rem;

    font-size: 24.7097rem;
    line-height: 30rem;
  }

  .popup-dark__reg {
    font-size: 16rem;
    line-height: 20rem;
  }

  .success {
    .popup-dark__content {
      padding-top: 109rem;
    }

    .popup-dark__container {
      height: 268rem;
    }

    .popup-dark__btn-close {
      top: 24rem;
    }
  }

  .popup-dark__text {
    margin-bottom: 40rem;
    font-size: 33rem;
    line-height: 40rem;
  }
}
