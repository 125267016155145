.popup-dark--forecast-result {
  .popup-dark__container {
    width: 1152rem;
  }
}

.winners__wrapper--forecast {
  display: block;
}


.forecast__list {
  @include list-reset;
  color: #FFFFFF;
  font-size: 25rem;
  font-weight: 500;
}

.forecast__item {
  display: flex;
  font-size: 17rem;
  margin-bottom: 20rem;

  &--main {
    display: none;
  }
}

.forecast__name {
  width: 180rem;
  order: 2;
  margin-right: 10rem;
}

.forecast__data {
  margin-right: 19rem;
  text-align: center;
  order: 1;
}

.forecast__info {
  text-align: right;
  order: 3;
}

@media screen and (min-width: $sm) {

  .forecast__item {
    font-size: 25rem;
    line-height: 45rem;

    &--main {
      display: flex;
      text-transform: uppercase;
      font-size: 25rem;
      font-weight: 700;
      color: #00C7B1;
      line-height: 50rem;
    }
  }

  .forecast__name {
    width: 530rem;
    order: 1;
  }

  .forecast__data {
    width: 187rem;
    text-align: center;
  }
  .forecast__info {
    width: 284rem;
    text-align: right;
  }
}
