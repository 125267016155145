@import 'variables';
@import 'fonts';
@import 'mixins';
@import 'reset';

// 1px ===  1rem
html,
body {
  position: relative;

  background-color: #000000;

  font-family: 'montserrat';
  font-size: 0.052vw;
  @media screen and (max-width: $sm) {
    font-size: 0.313vw;
  }
}

html {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
  min-height: $min;
  @media screen and (max-width: $sm) {
    min-height: auto;
  }
  max-width: 100vw;
}

* {
  -webkit-tap-highlight-color: transparent;
}

/* Работает в Firefox */
* {
  scrollbar-width: 8rem;
}

body {
  scrollbar-color: darken($lavand, 20%) #000000;
}

/* Работает в Chrome, Edge и Safari */
body::-webkit-scrollbar {
  width: 8rem;
}

body::-webkit-scrollbar-track {
  background: #000000; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  border: none; /* creates padding around scroll thumb */
  border-radius: 20rem; /* roundness of the scroll thumb */
  background-color: darken($lavand, 20%); /* color of the scroll thumb */
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}