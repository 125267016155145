.popup-promotion-end {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  cursor: pointer;
  overflow: scroll;

  &--close {
    display: none;
  }

  &--open {
    display: block;
  }
}

.popup-promotion-end__container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.popup-promotion-end__overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20rem;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-promotion-end__content {
  max-width: 1016rem;
  padding: 0 0 136rem;
  cursor: auto;
  background-color: black;
}

.popup-promotion-end__head {
  position: relative;
}

.popup-promotion-end__close-button {
  @include button-reset;

  position: absolute;
  top: 20rem;
  right: 20rem;

  width: 32rem;
  height: 32rem;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
  &::before,
  &:after {
    position: absolute;
    top: 50%;
    left: 0;

    width: 100%;
    height: 3.2rem;

    content: '';

    background: white;
  }
  &::before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(135deg);
  }
}

.popup-promotion-end__title {
  padding-top: 136rem;
  padding-left: 136rem;
  padding-right: 136rem;
  font-size: 95rem;
  font-weight: 900;
  line-height: 100%;
  color: white;
  text-align: center;
  margin-bottom: 61rem;
  text-transform: uppercase;
}

.popup-promotion-end__description {
  font-size: 43rem;
  padding-left: 136rem;
  padding-right: 136rem;
  line-height: normal;
  font-weight: 700;
  color: white;
  text-align: center;
  text-transform: uppercase;
  span {
    color: $lazur;
  }
}

@media screen and (max-width: $sm) {
    .popup-promotion-end__container  {
        position: relative;
        height: 100vh;
    }
    .popup-promotion-end__content {
        max-width: 100%;
        height: 100%;
        padding: 16rem 15rem 0;
    }

    .popup-promotion-end__overlay {
        padding: 0;
        background-color: #000000;
    }

    .popup-promotion-end__title {
        font-size: 38rem;
        padding: 129rem 0 0;
        margin-bottom: 43rem;
    }

    .popup-promotion-end__description {
        font-size: 23rem;
        padding: 0;
    }

    .popup-promotion-end__close-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 15rem;   
    
        &::before,
        &:after {
          height: 2rem;
        }
    }
}