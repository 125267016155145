.aside {
  position: relative;
  z-index: 1;

  display: flex;
  align-items: stretch;
  flex-flow: column;
  //justify-content: space-between;

  padding: 40rem 70rem 110rem;

  background-color: rgba(#000, .5);
}

.aside__head {
  display: flex;
  justify-content: center;

  & > :not(:last-child) {
    margin-right: 36rem;
  }
}

.aside__body {
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  margin: auto;
  max-height: 800rem;
  padding-top: 38rem;

  height: 100%;
}

.aside__stages {
  display: flex;
  justify-content: center;

  width: 90%;
  height: 95rem;
  margin: 0 auto;

  img {
    height: 100%;
  }
}

.aside__title {
  margin: 0 auto 15rem;
  font-size: 70rem;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 0.9;

  sup {
    display: inline-block;
    position: relative;
    color: $lazur;
    font-size: 46rem;
    top: 8rem;
  }
}

.aside__text {
  font-size: 30rem;
  font-weight: 900;
  text-transform: uppercase;
}

.aside__freebet {
  display: none;
  justify-content: center;

  width: 100%;
  height: 205rem;
  margin: 0 auto;

  img {
    height: 100%;
  }
}

.aside__timer-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 590rem;
  max-width: 100%;
  min-height: 74rem;
  margin: 0 auto 15rem;
  font-size: 30rem;
  border: 1rem solid #fff;
}

.aside__timer {
  color: $lazur;
  font-weight: 700;
}

.aside__timer-text {
  display: inline-block;
  text-transform: uppercase;
  padding-right: 10rem;
}

.aside__time {
  display: inline-block;
  text-align: center;
  padding: 0 5rem;

  &--seconds {
    min-width: 55rem;
    margin-left: -5rem;
  }
}

.aside__buttons {
  display: flex;
  flex-flow: column;

  margin: 0 auto;

  .button {
    margin: 0 auto;
    padding: 20rem 65rem;
    color: #000;

    &:first-child {
      margin-bottom: 20rem;
    }

    &::before {
      background-color: #fff;
      box-shadow: 0 16.5rem 36rem rgba(255, 255, 255, 0.38);

    }

    @media screen and (min-width: $sm) {
      &:hover,
      &:focus {
        &::before {
          box-shadow: 0 26rem 20rem rgba(255, 255, 255, 0.5);
        }
      }
    }

    &--rules {
      color: #ffffff;
    }
  }
}

.aside__prize {
  display: flex;
  max-width: 100%;
  margin-bottom: 15rem;
}

.aside__prize-inner {
  display: flex;

  &:first-child {
    margin-right: 10rem;
  }
}

.aside__icon-wrap {
  margin-right: 2rem;
  width: 97rem;
  height: 54rem;

  img {
    width: 100%;
    height: 100%;
  }
}

.aside__prize-text {
  font-size: 14rem;
  font-weight: 700;
  line-height: 19rem;
  text-transform: uppercase;
  align-self: center;

  span {
    color: $lazur;
  }
}

#freebet {
  transform-origin: center;
  transform: scale(1.5);
}

#thousand {
  opacity: 0;
}

.aside__winners-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}

.aside__winners {
  @include button-reset;
  display: inline-block;
  text-transform: uppercase;
  color: $lazur;
  font-size: 22rem;
  font-weight: 700;
  margin-bottom: 15rem;

  &:hover {
    opacity: 0.7;
  }

  &:last-child {
    color: #FFFFFF;

    .aside__winners-text {
      &::before {
        background-color: #FFFFFF;
      }
    }
  }
}

.aside__winners--general {
  margin-right: 30rem;
}

.aside__winners-text {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3rem;
    width: 100%;
    height: 3rem;
    background-color: $lazur;
  }
}

.asside__winners-arrow {
  display: inline-block;
  position: relative;
  top: 3rem;
  left: 4rem;
  height: 19rem;
  width: 41rem;

  svg {
    width: 100%;
    height: 100%;
  }
}
@media screen and (min-width: $sm) {
  .aside__buttons {
    order: 1;
  }
}

@media screen and (max-width: $sm) {
  .aside {
    padding: 0;
    margin-top: 15rem;
    background: none;
  }

  .aside__body {
    max-height: none;
    height: auto;
    padding-top: 5rem;
    justify-content: flex-start;
  }

  .aside__head {
    display: none;
  }

  .aside__title {
    margin-bottom: 28rem;
    font-size: 24rem;

    sup {
      font-size: 18rem;
      top: 3rem;
    }
  }

  .aside__text {
    margin-bottom: 23rem;
    font-size: 25rem;
    line-height: 28rem;
  }

  .aside__stages,
  .aside__freebet {
    display: flex;
    margin: 50rem auto;
  }

  .aside__stages {
    height: 50rem;
  }

  .aside__freebet--link {
    order: 4;
  }

  .aside__freebet {
    margin-top: 25rem;
    margin-bottom: 0;
    height: 85rem;
  }

  .aside__prize {
    display: block;
    max-width: 100%;
    margin-bottom: 0rem;
  }

  .aside__prize-inner {
    margin-bottom: 20rem;
  }

  .aside__icon-wrap {
    width: 81.36rem;
    flex-basis: 81.36rem;
    height: 48.47rem;
    margin-right: 7rem;
  }

  .aside__prize-text {
    max-width: 190rem;
    font-size: 12rem;
    line-height: 1.25;
  }

  .aside__timer-wrapper {
    position: relative;
    display: block;
    min-height: 69rem;
    padding-top: 7rem;
    margin-bottom: 22rem;
    font-size: 20rem;
    width: 100%;
    text-align: center;
    line-height: 1.3;

    &::before {
      content: '';
      bottom: 0;
      left: 0;
      width: 320rem;
      height: 400rem;
      background-image: url('../images/billboard/players-mobile.png');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .aside__time {
    padding: 0;
    margin: 0;

    &--seconds {
      min-width: 28rem;
    }
  }

  .aside__mobile-bg {
    display: block;
    width: 320rem;
    margin-left: -20rem;
    margin-right: -20rem;
    margin-bottom: 0;
    height: 455rem;
    background-image: url('../images/billboard/players-mobile.png');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .aside__buttons {
    order: 3;
    .button {
      width: 100%;
      font-size: 20rem;
      padding: 13.5rem 29rem;

      &::before {
        width: 120%;
      }

      &:first-child {
        margin-bottom: 4rem;
      }
    }

    .button--rules {
      font-size: 12rem;
    }
  }

  .aside__winners {
    margin-bottom: 32rem;
    //font-size: 17.7rem;
    font-size: 12rem;
  }

  .aside__winners-wrapper {
    //flex-direction: column;
    //align-items: center;
    justify-content: space-between;
  }

  .aside__winners--general {
    margin: 0;
    margin-bottom: 32rem;
  }

  .aside__winners-text {
    &:before {
      height: 2rem;
    }
  }

  .asside__winners-arrow {
    //height: 19rem;
    //width: 31rem;
    height: 10rem;
    width: 21rem;
    top: 1rem;
  }

}
