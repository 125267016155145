.popup-dark--plug {
   display: block;

   .popup-dark__title {
      margin-bottom: 42rem;
      max-width: 729rem;
      font-weight: 900;
      font-size: 46rem;
      line-height: 56rem;
      text-align: center;
      text-transform: uppercase;

      span {
         color: $lazur;
         text-transform: none;
      }
   }

   .popup-dark__container {
      height: 605rem;
   }

   .popup-dark__content {
      padding-top: 80rem;
      padding-left: 50rem;
      padding-right: 50rem;
   }

   .popup-dark__text {
      margin-bottom: 27rem;
      font-weight: 400;
      font-size: 24rem;
      line-height: 34rem;
   }

   .popup-dark__btn-wrapper {
      display: flex;
      flex-direction: column;
   }

   .popup-dark__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 467rem;
      min-height: 78rem;
      color: black;
      font-weight: 700;
      font-size: 28.3062rem;
      line-height: 35rem;
      text-decoration: none;

      &::before {
         border-radius: 9.8526rem;
         background-color: white;
         box-shadow: 0 14.1944rem 30.8573rem rgba(255, 255, 255, 0.5);
      }
   }

   .popup-dark__btn--lazur {
      &::before {
         border-radius: 9.8526rem;
         background-color: #00C7B1;
         box-shadow: 0 14.1944rem 30.8573rem rgba(0, 199, 177, 0.5);
      }
   }

   @media screen and (max-width: $sm) {
      .popup-dark__title {
         margin-bottom: 47rem;
         font-size: 23rem;
         line-height: 28rem;
      }

      .popup-dark__text {
         margin-bottom: 31rem;
         font-size: 12rem;
         line-height: 14rem;
      }

      .popup-dark__btn {
         width: 100%;
         min-height: 57rem;
         font-size: 18rem;
         line-height: 22rem;
      }

      .popup-dark__container {
         height: 100%;
      }

      .popup-dark__content {
         padding-top: 0;
         padding-left: 17rem;
         padding-right: 17rem;
      }
   }
}
