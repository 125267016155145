.winners {
  font-size: 25rem;
  color: #FFFFFF;
  font-weight: 500;

  .popup-dark__title {
    margin-top: 25rem;
  }
}

.winners__list {
  @include list-reset;

  overflow-y: scroll;
  max-height: 400rem;

  //todo: добавить кастомный скролл
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.winners__item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.winners__info {
  text-align: right;
}

.winners__name {
  pointer-events: none;
}

.winners__wrapper {
  display: none;

  &.is-active {
    display: block;
  }

  &--match {
    .popup-dark__title {
      margin-bottom: 8rem;
    }

    .winners__item:first-child {
      color: $lazur;
    }
  }

}

.winners__match-name {
  text-align: center;
  margin-bottom: 50rem;
}

.winners__btn-back {
  @include button-reset;
  position: absolute;
  top: 30rem;
  left: 40rem;
  padding-left: 25rem;
  font-size: 22rem;
  font-weight: 500;
  color: #FFFFFF;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    left: 0;
    display: block;
    width: 17rem;
    height: 17rem;
    border: 3rem solid #FFFFFF;
    border-top: none;
    border-right: none;
  }
}

@media screen and (min-width: $sm) {
  .winners__btn-back {
    &:hover {
      color: $lazur;

      &::before {
        border-color: $lazur;
      }
    }
  }

  .winners__wrapper--list {
    .winners__item {
      &:hover {
        color: $lazur;
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .winners {
    font-size: 18rem;
    min-height: 100vh;

    .popup-dark__title {
      margin: 50rem auto 35rem;
    }
  }

  .winners__item {
    margin-bottom: 20rem;
  }

  .winners__wrapper--match {
    .winners__name {
      margin-right: 10rem;

    }
  }

  .winners__btn-back {
    top: -5rem;
    left: 30rem;
    font-size: 17rem;

    &::before {
      width: 15rem;
      height: 15rem;
    }
  }
}
